import { Text, Flex, Divider, Box, Button, useColorModeValue as mode, useToast } from "@chakra-ui/react";
import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowDownIcon } from "@chakra-ui/icons";
import Feature from './Feature';
import { FaFilePdf, FaFolder, FaMagic, FaVideo } from "react-icons/fa";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack } from "@chakra-ui/react";



function AKTopicJSONLoad({data}) {
    const toast = useToast()

    var history = useHistory();


    const handleLoad = (data) => {
        toast({
            title: "Loading " + data.Title,
            description: "Please Wait.......",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })

        if (data.material_type == "VIDEO") {


            fetch(`https://apnikaksha.shreeram4.repl.co/decryptVideo?data=${encodeURIComponent(data.media_id)}`)
                .then(response => response.text())
                .then(long_url => {
                    history.push(`/play?url=${encodeURIComponent("https://content.jwplatform.com/manifests/" + long_url + ".m3u8")}&name=${encodeURIComponent(data.Title)}`);
                })
        } else {

            fetch(`https://apnikaksha.shreeram4.repl.co/decryptVideo?data=${encodeURIComponent(data.file_link)}`)
                .then(response => response.text())
                .then(long_url => {
                    history.push(`/pdf?url=${encodeURIComponent(long_url)}&name=${encodeURIComponent(data.Title)}`);
                })

        }



    }


    var bg = mode('#151f21', 'gray.900');


    return (

        <Flex direction='column' wrap={'wrap'}>
            <Button onClick={() => { handleLoad(data) }} marginBottom="20px">
                <Feature
                    icon={
                        data.material_type == "VIDEO" ? <FaVideo /> : <FaFilePdf />
                    }
                    text={data.Title}
                />
            </Button>
        </Flex>

    )
}



export default function AKSubjectJSONLoad(batch_id, subject_id) {
    var { batch_id, subject_id } = useParams();

    var { loading, error, data } = useFetch(`apnikaksha/topics/${batch_id}_${subject_id}.json`);

    return (
        <>

            {loading && <SkeletonPage />}

            <Flex direction='column' wrap={'wrap'} px={10}>


                <Accordion allowToggle>
                    {data && !error &&
                        data.map((s, i) => (
                            // <Button key={i+1} onClick={()=>{handleLoad(s.media_id,s.Title)}} marginBottom="20px">
                            //     <ViewDataSingle s={s} />
                            // </Button>

                            <Box key={i + 1}>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <HStack height={'50px'} padding={2}>
                                                <Feature
                                                    icon={
                                                        <FaFolder w={5} h={5} />
                                                    }
                                                    text={s.topic_name}
                                                />
                                            </HStack>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>


                                        <Accordion allowToggle>

                                            {"subtopics" in s && s.subtopics.map((ele, i) => (
                                                <Box key={i + 1}>
                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <HStack height={'50px'} padding={2}>
                                                                    <Feature
                                                                        icon={
                                                                            <FaMagic w={5} h={5} />
                                                                        }
                                                                        text={ele.concept_name}
                                                                    />
                                                                </HStack>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            {ele.details.map((el, i) => (
                                                                <AKTopicJSONLoad key={i + 1} data={el} />
                                                            ))}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Box>
                                            ))}

                                        </Accordion>


                                    </AccordionPanel>
                                </AccordionItem>

                            </Box>
                        ))

                    }
                </Accordion>


            </Flex>


            {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

        </>

    );

}
