import { Heading, Text, Flex, Tabs, Tab, TabPanels, TabPanel, TabList } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import SubjectLoad from "../../components/fire/SubjectLoad";

//firebase
import { db } from '../../hooks/firebase/useFirebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";


export default function FireCourceDetails() {

    var { index } = useParams();

    const [data, setData] = useState([]);

    useEffect(async () => {

        var d = [];

        const q = query(collection(db, "subjects"), where("course_id", "==", index));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            //   console.log(`${doc.id} =>`,doc.data());
            d.push(doc.data());
        });

        setData(d);
        console.log(d);
    }, []);



    return (
        <>
            <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>

                <Heading
                    fontWeight={'bold'}
                    fontSize={'3xl'}
                    lineHeight={'110%'}>
                    Cource{' '}
                    <Text as={'span'} color={'orange.400'}>
                        Subjects..
                    </Text>
                </Heading>


                <br />

                <Tabs variant='soft-rounded' colorScheme={'green'} >
                    <TabList flexWrap={'wrap'} >
                        {data &&
                            data.map((s, i) => (
                                <Tab key={i + 1}
                                >
                                    {s.name}
                                </Tab>
                            ))
                        }
                    </TabList>

                    <TabPanels>
                        {data &&
                            data.map((s, i) => (
                                <TabPanel key={i + 1}
                                >
                                    <SubjectLoad subject={s.id} batch={index} />

                                </TabPanel>
                            ))
                        }
                    </TabPanels>

                </Tabs>



            </Flex>
        </>

    )
}
