import { Text, Flex, Divider, Box, useColorModeValue as mode } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import SingleDataCard from "../singledatacard";

//firebase
import { db, dbVid } from '../../hooks/firebase/useFirebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";


function ViewDataSingle({ s, shownotes }) {
    var bg = mode('#151f21', 'gray.900');

    return shownotes ?
        <Box as="section" py="2">
            <Box maxW="3xl" mx="auto" px={{ md: '8' }}
                borderWidth='1px'
                bg={mode('white', 'gray.700')}
                rounded={{ md: 'lg' }}
                shadow="base"
                overflow="hidden"
                borderRadius='20px'
            >
                <Box px="6" py="4">

                    <Text as="h3" fontWeight="bold" fontSize="md">
                        {s.name}
                    </Text>
                </Box>
                <Divider />
            </Box>
        </Box>


        : <> <SingleDataCard key={s.id} thumb={s.image} title={s.name + " (" + s.duration + ")"} />  <br /> </>;
}


export default function SubTopicLoad({ subject, batch, tag, type }) {

    //  types:
    //  videos
    //  notes
    // DppNotes
    // DppVideos

    var shownotes = type == "notes" || type == "DppNotes" ? true : false;



    const [data, setData] = useState([]);

    useEffect(async () => {

        var d = [];

        var q;

        if (type == "notes") {

            var q = query(collection(db, "notes"), where("course_id", "==", batch), where("subject_id", "==", subject), where("topic_id", "==", tag));
        }

        if (type == "videos") {

            var q = query(collection(dbVid, "videos"), where("course_id", "==", batch), where("subject_id", "==", subject), where("topic_id", "==", tag));
        }

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            //   console.log(`${doc.id} =>`,doc.data());
            d.push(doc.data());
        });

        setData(d);
        console.log(d);
    }, []);




    var bg = mode('#151f21', 'gray.900');
    return (

        <>


            <Flex direction='column' wrap={'wrap'} alignItems={'center'}>

                {data &&
                    data.map((s, i) => (
                        <>
                            <Link to={
                                shownotes ? `/pdf?url=${encodeURIComponent(s.url)}&name=${encodeURIComponent(s.name)}`
                                    : `/play?url=${encodeURIComponent(s.url.replace(".mpd", ".m3u8"))}&name=${encodeURIComponent(s.name)}`
                            }
                            key={i+1}
                            >

                                <ViewDataSingle shownotes={shownotes} s={s} />


                            </Link>
                        </>
                    ))

                }
            </Flex>


        </>

    )
}
