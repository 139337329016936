import {  Text,  Flex, Divider, Box,  useColorModeValue as mode } from "@chakra-ui/react";
import useFetch from "../../hooks/useFetch";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import { Link } from 'react-router-dom';
import SingleDataCard from "../singledatacard";

function ViewDataSingle({s,shownotes}) {
    var bg = mode('#151f21', 'gray.900');

    return shownotes ? 
<Box as="section" py="2">
<Box maxW="3xl" mx="auto" px={{ md: '8' }}
    borderWidth='1px' 
    bg={mode('white', 'gray.700')}
    rounded={{ md: 'lg' }}
    shadow="base"
    overflow="hidden"
    borderRadius='20px'
    >
        <Box px="6" py="4">
            
            <Text as="h3" fontWeight="bold" fontSize="md">
            {s["homeworkIds"][0]["topic"]}
            </Text>
        </Box>
        <Divider />
    </Box>
</Box>
    
    
    : <> <SingleDataCard key={s._id} thumb={s.videoDetails.image} title={s.topic + " ("+ s.videoDetails.duration+")"} />  <br/> </>;
}


export default function SubTopicLoad({ subject, batch, tag, type }) {

    //  types:
    //  videos
    //  notes
    // DppNotes
    // DppVideos

    var shownotes = type == "notes" || type == "DppNotes" ? true : false;



    var { loading, error, data } = useFetch("getSubTopicDetails?batch_id=" + batch + "&subject_id=" + subject + "&tag=" + tag + "&type=" + type);

    var bg = mode('#151f21', 'gray.900');
    return (

        <>


            {loading && <SkeletonPage />}

            <Flex direction='column' wrap={'wrap'} alignItems={'center'}>

                {data && !error &&
                    data.map((s, i) => (
                        <>
                            <Link to={
                                shownotes ? `/pdf?url=${encodeURIComponent(s["homeworkIds"][0]["attachmentIds"][0]["baseUrl"] + s["homeworkIds"][0]["attachmentIds"][0]["key"])}&name=${encodeURIComponent(s["homeworkIds"][0]["topic"])}`
                                    : `/play?url=${encodeURIComponent(s.url.replace(".mpd", ".m3u8"))}&name=${encodeURIComponent(s["topic"])}`
                            }
                            key={i+1}
                            >

                                <ViewDataSingle shownotes={shownotes} s={s}  />


                            </Link>
                        </>
                    ))

                }
            </Flex>

            {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

        </>

    )
}

