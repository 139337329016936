import { Heading, Text,  Flex,  Tabs, Tab, TabPanels, TabPanel, TabList} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import useFetch from "../../hooks/useFetchPWJSON";
 import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import SubjectLoad from "./SubjectLoad";
var loadAsh = require('lodash');


export default function ScorePlusCourceDetails() {

  var { id } = useParams();

  
   var { loading, error, data } = useFetch(`scoreplus/topics/${id}.json`);


   if(data){
    data = loadAsh.groupBy(data,"subject");
   }

  return (
    <>
      <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>
      {loading && <SkeletonPage />}

        <Heading
          fontWeight={'bold'}
          fontSize={'3xl'}
          lineHeight={'110%'}>
          Cource{' '}
          <Text as={'span'} color={'orange.400'}>
            Subjects.
          </Text>
        </Heading>


        {/* 
        <Flex direction='row' wrap={'wrap'} alignItems={'center'}>

          {data && !error &&
            data?.subjects?.map((s, i) => (
              <Button
                mt={8}
                marginLeft={8}
                rounded={'md'}
                _activeLink={
                  {
                    color: 'white',
                    backgroundColor: 'blue'
                  }
                }
              >
                {s.subject}
              </Button>
            ))

          }
        </Flex> */}


        <br />


        <Tabs variant='soft-rounded' colorScheme={'green'} >
          <TabList flexWrap={'wrap'} >
            {  data && !error && loadAsh.keys(data).map((s, i) => (
                <Tab key={i + 1}
                >
                  {s}
                </Tab>
              ))
            }
          </TabList>

          <TabPanels>
            { data && !error &&  loadAsh.keys(data).map((s, i) => (
                <TabPanel key={i + 1}
                >
                  {/* <SubjectLoad subject={s._id} /> */}
                  <SubjectLoad subjectData={data[s]} />
                  
                  </TabPanel>
              ))
            }
          </TabPanels>

        </Tabs>


        {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

      </Flex>

    </>

  )
}
