import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    VisuallyHidden,
    useColorModeValue
  } from '@chakra-ui/react';
  
  import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
  import { ReactNode } from 'react';
  
  import SvgLogo from '../Svglogo';
  
  
  const Footer = () => {
    return (
      <div style={{ padding: 5, bottom: 0 }}>
        <Box
          bg={useColorModeValue('gray.50', 'gray.900')}
          color={useColorModeValue('gray.700', 'gray.200')}>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
  
            <SvgLogo full={1} />
            <Text>© 2022 Physics Wallah. Made with love ❤️ by Some People</Text>
          </Container>
        </Box>
  

      </div>
    );
  }
  
  export default Footer;