import {  Text,  Flex, Divider, Box, useColorModeValue as mode} from "@chakra-ui/react";
import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import { Link } from 'react-router-dom';

export default function SubjectLoad({ subject }) {
   var { loading, error, data } = useFetch(`topics/${subject}.json`);

    return (

        <>


            {loading && <SkeletonPage />}

            <Flex direction='column-reverse' wrap={'wrap'} alignItems={'center'}>

                {data && !error &&
                    data.map((s, i) => (
                        <>
                            <Link key={i+1} to={`/pwjsontopic/${s._id}`}>
                                {/* <Button
                            w={'full'}
                            key={i+1}
                                mt={8}
                                bg={bg}
                                color={'white'}
                                rounded={'md'}
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                {s.name}

                            </Button> */}

                                <Box as="section" py="2" mx="3">
                                    <Box maxW="3xl" mx="auto" px={{ md: '8' }}
                                        borderWidth='1px' 
                                        bg={mode('white', 'gray.700')}
                                        rounded={{ md: 'lg' }}
                                        shadow="base"
                                        overflow="hidden"
                                        borderRadius='20px'
                                        >
                                            <Box px="6" py="4">
                                                <Text as="h3" fontWeight="bold" fontSize="md">
                                                 {s.name}
                                                </Text>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>
                            </Link>
                        </>
                    ))

                }
            </Flex>

            {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

        </>

    )
}
