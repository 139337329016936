
import { AnimatePresence } from "framer-motion";
import Navbar from './components/Navbar';
import { Route, Switch, useLocation } from "react-router-dom";
import Fallback from './pages/Fallback';
import CourceDetails from './pages/cource/CourceDetails';
import {default as SheetsCourceDetails} from './pages/sheets/CourceDetails';
import {default as AKCourceDetails} from './pages/ak/CourceDetails';
import HomePage from './pages/home/HomePage';
import { ChakraProvider } from '@chakra-ui/react'
import TopicLoad from "./components/pw/TopicLoad";
import {default as FireTopicLoad} from './components/fire/TopicLoad';

import Player from "./components/Basic/player";
import PdfView from "./components/Basic/pdf";
import Settings from "./pages/Settings";
import Footer from "./components/Footer";
import SingleDataComponent from "./components/loadSingleData";
import AKTopicLoad from "./components/ak/TopicLoad";
import FireCourceDetails from "./pages/fire/FireCourceDetails";
import CourceDetailsJSON from "./pages/cource/CourceDetailsJSON";
import TopicLoadJSON from "./components/pwjson/TopicLoadJSON";
import ScorePlusCourceDetails from "./pages/scoreplus/ScorePlusCourceDetails";
import ApniKakshaHomeJSON from "./pages/akjson/ApniKakshahomeJSON";
import AKCourceDetailsJSON from "./pages/akjson/CourceDetailsJSON";
import AKSubjectJSONLoad from "./components/akjson/SingleSubjectLoad";

function App() {
  const location = useLocation();

  return (
    <ChakraProvider>

    <div className="App">
    <Navbar />
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>


        <Route exact path="/">
          <HomePage />
        </Route>

        <Route exact path="/pwcource/:id">
          <CourceDetails />
        </Route>

        <Route exact path="/pwjsoncource/:cid">
          <CourceDetailsJSON />
        </Route>


        <Route exact path="/scorepluscource/:id">
          <ScorePlusCourceDetails />
        </Route>

        

        <Route exact path="/pwtopic/:batch_id/:subject_id/:tag">
          <TopicLoad />
        </Route>

        <Route exact path="/pwjsontopic/:tag">
          <TopicLoadJSON />
        </Route>

        
        <Route path="/play">
          <Player />
        </Route>


        <Route path="/pdf">
          <PdfView />
        </Route>

        <Route path="/settings">
          <Settings />
        </Route>

      

        <Route path="/sheetscource/:index">
          <SheetsCourceDetails />
        </Route>

        <Route path="/sheetsSingleData">
          <SingleDataComponent />
        </Route>

        <Route path="/akcource/:index">
          <AKCourceDetails />
        </Route>

        <Route exact path="/aktopic/:batch/:subject/:topic">
          <AKTopicLoad />
        </Route>




        <Route path="/firecource/:index">
          <FireCourceDetails />
        </Route>




        <Route exact path="/firetopic/:batch_id/:subject_id/:tag">
          <FireTopicLoad />
        </Route>





{/* 
        <Route exact path="/firebasecource/:id">
          <FireCourceDetails />
        </Route>

        
        <Route exact path="/sheetscource/:id">
          <SheetsCourceDetails />
        </Route> */}



<Route path="/akcourcejson">
          <ApniKakshaHomeJSON />
        </Route>

        <Route path="/akjsoncource/:index">
          <AKCourceDetailsJSON />
        </Route>

        <Route path="/akjsontopic/:batch_id/:subject_id">
          <AKSubjectJSONLoad />
        </Route>

        

        <Route exact path="/fallback">
          <Fallback type={'201'} />
        </Route>

        <Route path="">
          <Fallback type={'404'} />
        </Route>

        <Route path="*">
          <Fallback type={'404'} />
        </Route>


      </Switch>
    </AnimatePresence>
    <Footer />
  </div>
  </ChakraProvider>

  );
}

export default App;
