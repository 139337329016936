
import { HStack, VStack, Flex, Box, IconButton, useColorMode, Heading, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@chakra-ui/react'

import { ColorModeSwitcher } from '../ColorModeSwitcher';
import SvgLogo from '../Svglogo';
import { useHistory } from "react-router-dom";

import { AiTwotoneSetting } from 'react-icons/ai';


function Navbar() {
    let history = useHistory();

    return (
        <>
                <Box className='header' paddingX={2} paddingY={5} marginBottom={5} >
                    <Flex as="header" width="100%" align="center" px={10} wrap={'wrap'} paddingBottom={2}>
                        <Link to="/">
                            <a>
                                <HStack >
                                    <Box w={50} >
                                        <SvgLogo />
                                    </Box>
                                    <Spacer w={5}>

                                    </Spacer>
                                    <Heading as="h1" size="xl"
                                        fontWeight='extrabold'

                                        bgGradient={`linear(to-r, pink.500, pink.300, blue.500)`}
                                        bgClip='text'>

                                        PhysicsWallah
                                    </Heading>
                                </HStack>
                            </a>
                        </Link>


                        <Box marginLeft="auto">

                            <Tooltip label='Settings'>
                                <IconButton
                                    icon={<AiTwotoneSetting />}
                                    isRound='true'
                                    size='lg'
                                    marginRight={5}
                                    alignSelf='flex-end'
                                    onClick={() => {
                                        history.push(
                                            "/settings"
                                        );
                                    }}
                                />
                            </Tooltip>

                                <ColorModeSwitcher />

                        </Box>
                    </Flex>

                </Box>
        </>
    );
}

export default Navbar;
