import { Heading, Text,  Flex,  Tabs, Tab, TabPanels, TabPanel, TabList, Stack} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import SubjectLoad from "../../components/ak/SubjectLoad";
import CourceCard from "../../components/CourceCard";

export default function AKCourceDetailsJSON() {

  var { index } = useParams();

  var { loading, error, data } = useFetch(`apnikaksha/subjects/${index}.json`);



  return (
    <>
      <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>

        <Heading
          fontWeight={'bold'}
          fontSize={'3xl'}
          lineHeight={'110%'}>
         Cource{' '}
          <Text as={'span'} color={'orange.400'}>
            Subjects..
          </Text>
        </Heading>


        {loading && <SkeletonPage />}


        {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}


        <br />

        <Stack spacing={8} direction='row' wrap={'wrap'} alignItems={'center'} justifyContent={'center'}>

            {data && !error &&
              data.map((c, i) => (
                <CourceCard key={i + 1} name={c.subject_name} subhead={`${c.subject_name}`} link={`/akjsontopic/${index}/${c.subjectid}`} image={`${c.subject_logo}`} />
              ))
            }

            </Stack>




      </Flex>
    </>

  )
}
