import {useLocation,useHistory} from 'react-router-dom';
import { Stack, Container, Heading, Text, useColorModeValue, Link, Button, Spacer, Box, Divider } from "@chakra-ui/react";
import JWPlayer from '../components/JWPLAYER';
import { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { isIOS, isMacOs } from 'react-device-detect';
import UnacademyNotesLoader from "../components/dataLoader/unacademyNotes";


const SingleDataComponent = () => {
    var bgx = useColorModeValue('white', 'gray.700');
    const toast = useToast()
const history = useHistory();
    const stringToBoolean = (string) => {

        if (!string) return false;
        switch (string.toLowerCase().trim()) {
            case "true":
            case "yes":
            case "1":
                return true;

            case "false":
            case "no":
            case "0":
            case null:
                return false;

            default:
                return Boolean(string);
        }
    }

    const [isDownloading, setisDownloading] = useState(false);

    const downloadFile = (url, fileName) => {
        setisDownloading(true);
        axios({
            url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            setisDownloading(false);
            const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobbedResponse;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }


    var loc = useLocation();
    var url = new URLSearchParams(loc.search);
    
    var data = [];

    for(var pair of url.entries()) {
        data[pair[0]]  = pair[1];
     }


    useEffect(() => {
        toast({
            title: "Now Showing " + data?.name,
            description: "We've played content for you.",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })

        // codes using router.query
        // console.log(router.query);

    }, []);

    try {

        console.log(data);

        if ("lecture" in data) {
            var ext = data.lecture.split('.').pop();

            ext = ext == 'mp4' ? 'mkv' : ext;

            if (stringToBoolean(localStorage.getItem('useOldPlayer')) || isIOS || isMacOs) {
                ext = ext == 'm3u8' ? 'superm3u8' : ext;
            }

            if (data.lecture.includes("yout")) {
                ext = "youtube";
                data.lecture = data.lecture.replace("https://youtu.be/","");        

            }

        }

        // if ("dpp" in data) {
        //     if(data.dpp == undefined) delete data.dpp;
        //     data.dpp =  data.dpp.contains("cdn.score") ? "https://corsdev.up.railway.app/" + data.dpp :  data.dpp;
        // }

        // if ("notes" in data) {
        //     if(data.notes == undefined) delete data.notes;
        //     data.notes =  data.notes.contains("cdn.score") ? "https://corsdev.up.railway.app/" + data.notes :  data.notes;
        // }




        return (<Box px={{ base: 1, lg: 20 }}>

            <Button
                /* flex={1} */
                onClick={history.goBack}
                px={4}
                fontSize={'sm'}
                bg={'blue.400'}
                color={'white'}
                boxShadow={
                    '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                    bg: 'blue.500',
                }}
                _focus={{
                    bg: 'blue.500',
                }}>
                <ArrowBackIcon />
                Go Back
            </Button>

            <Spacer py={'2%'} />


            {/* <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        {`${data.name}` + ' '}
                        <Text as={'span'} color={'red.400'}>
                            Contents
                        </Text>
                    </Heading> */}



            {"lecture" in data ?
                <>
                    <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        Lecture {' '}
                    </Heading>

                    {(data.lecture.includes("cdn") || data.lecture.includes('cloudfront')) &&
                                    <Button
                                    /* flex={1} */
                                    onClick={() => {
                                        window.location.replace("https://pwapi.flixyback.repl.co/player.php?url="+data.lecture.replace(".m3u8",".mpd"));
                                    }}
                                    px={4}
                                    fontSize={'sm'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    boxShadow={
                                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                                    }
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}>
                                    Open in DRM Player
                                </Button>


                    }
                    <br />


                    {/* Copy Lecture Link */}

                    <Box px={{ lg: 20 }} >
                        <JWPlayer title={`${data.name}`} url={`${data.lecture}`} extension={ext} poster={"https://physicswallah.pages.dev/uploads/physicswallah.png"} height={{ base: "100%", lg: "80%", }} />
                    </Box>

                    <Spacer py={'1%'} />

                    <Link href={`vlc://${data.lecture}`}>Open Lecture In vlc player (Works on android only)</Link>
                    <br />
                    <Link href={`intent:${data.lecture}#Intent;package=com.mxtech.videoplayer.ad;S.title=Lecture%201;end`}>Open
                        Lecture In MX player (Works on android only)</Link>
                </>
                : <></>}


            <Spacer py={'3%'} />
            <Divider />

            {"notes" in data && data.notes ?
                <div>
                    <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        Notes {' '}
                    </Heading>
                    <Spacer py={3} />
                    <Button
                        isLoading={isDownloading}
                        px={4}
                        fontSize={'sm'}
                        bg={'blue.500'}
                        color={'white'}
                        boxShadow={
                            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                        }
                        _hover={{
                            bg: 'blue.600',
                        }}
                        _focus={{
                            bg: 'blue.600',
                        }}
                        loadingText='Downloading'
                        colorScheme='teal'
                        variant='outline'
                        onClick={() => downloadFile(`${data.notes}`, `${data.name} Notes.pdf`)}
                    >
                        <ArrowDownIcon />
                        Download Notes
                    </Button>
                    <Spacer py={3} />


                    <iframe src={`https://physicswallah.pages.dev/pdf/viewer.html?file=${encodeURIComponent(data.notes)}`} style={{ border: 0, width: '100%', height: '500px' }}>Your
                    </iframe>

                    {/* <div><a href={`${data.dpp}`}>DPP Link</a></div> */}
                    <br />




                </div>
                : <div></div>}


            <Spacer py={'3%'} />
            <Divider />



            {"dpp" in data  && data.dpp ?
                <>
                    <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        DPP {' '}
                    </Heading>
                    <Spacer py={3} />
                    <Button
                        isLoading={isDownloading}
                        loadingText='Downloading'
                        colorScheme='teal'
                        px={4}
                        fontSize={'sm'}
                        bg={'blue.500'}
                        color={'white'}
                        boxShadow={
                            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                        }
                        _hover={{
                            bg: 'blue.600',
                        }}
                        _focus={{
                            bg: 'blue.600',
                        }}
                        variant='outline'
                        onClick={() => downloadFile(`${data.dpp}`, `${data.name} DPP.pdf`)}
                    >
                        <ArrowDownIcon />

                        Download DPP
                    </Button>
                    <Spacer py={3} />



                    <iframe src={`https://physicswallah.pages.dev/pdf/viewer.html?file=${encodeURIComponent(data.dpp)}`} style={{ border: 0, width: '100%', height: '500px' }}>Your
                    </iframe>



                    {/* <div><a href={`${data.dpp}`}>NOTES Link</a></div> */}


                    <br />

                </>
                : <></>}


            {"description" in data && data.description.includes("unacademy") && data.description.includes("PDF Notes:") ?
                <>
                  {/* <Text>
                      {data.description}
                  </Text> */}

                  <UnacademyNotesLoader ytdescription={data.description} name={data.name} />

                    <br />

                </>
                : <></>}



        </Box>);
    } catch (err) {
        console.log(err)
        return (
            <Container maxW={'100%'} bg={bgx} paddingBottom={10}>
                <Stack
                    textAlign={'center'}
                    align={'center'}
                    spacing={{ base: 4, md: 6 }}
                    py={{ base: 10, md: 10 }}>
                    <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        Invalid{' '}
                        <Text as={'span'} color={'red.400'}>
                            Data
                        </Text>
                    </Heading>
                </Stack>
            </Container>
        );
    }
}


export default SingleDataComponent;