import {  Text,  Flex, Divider, Box, useColorModeValue as mode} from "@chakra-ui/react";
import { Link } from 'react-router-dom';

//firebase
import { db } from '../../hooks/firebase/useFirebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";


export default function SubjectLoad({ subject, batch }) {


    const [data, setData] = useState([]);

    useEffect(async () => {

        var d = [];

        const q = query(collection(db, "playlists"), where("course_id", "==", batch), where("subject_id", "==", subject));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            //   console.log(`${doc.id} =>`,doc.data());
            d.push(doc.data());
        });

        setData(d);
        console.log(d);
    }, []);


    return (

        <>



            <Flex direction='column' wrap={'wrap'} alignItems={'center'}>

                {data &&
                    data.map((s, i) => (
                        <>
                            <Link key={i+1} to={`/firetopic/${batch}/${subject}/${s.id}`}>
                                <Box as="section" py="2" mx="3">
                                    <Box maxW="3xl" mx="auto" px={{ md: '8' }}
                                        borderWidth='1px' 
                                        bg={mode('white', 'gray.700')}
                                        rounded={{ md: 'lg' }}
                                        shadow="base"
                                        overflow="hidden"
                                        borderRadius='20px'
                                        >
                                            <Box px="6" py="4">
                                                <Text as="h3" fontWeight="bold" fontSize="md">
                                                 {s.name}
                                                </Text>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>
                            </Link>
                        </>
                    ))

                }
            </Flex>


        </>

    )
}
