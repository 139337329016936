import { Heading, Text, Stack, Flex, Button, Tabs, Tab, TabPanels, TabPanel, TabList,useColorModeValue} from "@chakra-ui/react";
import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../SkeletonPage/SkeletonPage";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SubTopicLoad from "./SubTopicLoad";

export default function TopicLoadJSON() {
    var {tag } = useParams();

    var { loading, error, data } = useFetch(`subtopics/${tag}.json`);

    //  types:
    //  videos
    //  notes
    // DppNotes
    // DppVideos


    var bg = useColorModeValue('#151f21', 'gray.900');
    return (

        <>
                    {loading && <SkeletonPage />}

                  <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>


                  {data && !error && (

            <Tabs variant='soft-rounded' colorScheme={'green'} >
          <TabList flexWrap={'wrap'} >

          <Tab>
                    Lectures
                </Tab>

                <Tab>
                    Notes
                </Tab>

                <Tab>
                    DPP PDFs
                </Tab>


                <Tab>
                    DPP Videos
                </Tab>
            
          </TabList>

          <TabPanels>

          <TabPanel>
                <SubTopicLoad datal={data.videos} type="videos" />
                </TabPanel>

                
                <TabPanel>
                <SubTopicLoad datal={data.notes} type="notes" />
                </TabPanel>

                
                <TabPanel>
                <SubTopicLoad datal={data.DppNotes} type="DppNotes" />
                </TabPanel>

                
                <TabPanel>
                <SubTopicLoad datal={data.DppVideos} type="DppVideos" />
                </TabPanel>

          </TabPanels>

        </Tabs> )}

</Flex>
{error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

        </>

    )
}
