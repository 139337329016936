import { Text, Flex, Divider, Box,Button, useColorModeValue as mode ,useToast} from "@chakra-ui/react";
import useFetch from "../../hooks/useFetchAK";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowDownIcon } from "@chakra-ui/icons";

function ViewDataSingle({ s }) {
    var bg = mode('#151f21', 'gray.900');


    return <Box as="section" py="2">
        <Box maxW="3xl" mx="auto" px={{ md: '8' }}
            borderWidth='1px'
            bg={mode('white', 'gray.700')}
            rounded={{ md: 'lg' }}
            shadow="base"
            overflow="hidden"
            borderRadius='20px'
        >
            <Box px="6" py="4">

                <Text as="h3" fontWeight="bold" fontSize="md">
                    {s.Title}
                </Text>
            </Box>
            <Divider />
        </Box>
    </Box>;


}


export default function AKTopicLoad() {
    const toast = useToast()

    var { subject, batch, topic } = useParams();

    var history = useHistory();

    const handleLoad = (mediaid,name) => {
        toast({
            title: "Playing " + name,
            description: "Please Wait.......",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })

            fetch(`https://apnikaksha.shreeram4.repl.co/decryptVideo?data=${encodeURIComponent(mediaid)}`)
            .then(response => response.text())
            .then(long_url => {
              history.push(`/play?url=${encodeURIComponent(long_url)}&name=${encodeURIComponent(name)}`);
            })
            
        
    }

    var { loading, error, data } = useFetch("get/livecourseclassbycoursesubtopconceptapiv3?start=-1&courseid="+batch+"&subjectid="+subject+"&topicid="+topic+"&conceptid=1");

    var bg = mode('#151f21', 'gray.900');
    return (

        <>
            <Button
        /* flex={1} */
        onClick={history.goBack}
        px={4}
        fontSize={'sm'}
        bg={'blue.400'}
        marginLeft={10}
        color={'white'}
        boxShadow={
          '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
        }
        _hover={{
          bg: 'blue.500',
        }}
        _focus={{
          bg: 'blue.500',
        }}>
        <ArrowBackIcon />
        Go Back
      </Button>
      <br/>


            {loading && <SkeletonPage />}

            <Flex direction='column' wrap={'wrap'}>


      
                {data && !error &&
                    data["data"].map((s, i) => (
                            <Button key={i+1} onClick={()=>{handleLoad(s.media_id,s.Title)}} marginBottom="20px">
                                <ViewDataSingle s={s} />
                            </Button>
                    ))

                }
            </Flex>

            {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}

        </>

    )
}
