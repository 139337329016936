import { Heading, Text, Stack, Flex, Button, Tabs, Tab, TabPanels, TabPanel, TabList, useColorModeValue } from "@chakra-ui/react";

import { useParams } from 'react-router-dom';
import SubTopicLoad from "./SubTopicLoad";

export default function TopicLoad() {
    var { subject_id, batch_id, tag } = useParams();

    //  types:
    //  videos
    //  notes
    // DppNotes
    // DppVideos


    var bg = useColorModeValue('#151f21', 'gray.900');
    return (

        <>
            <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>


                <Tabs variant='soft-rounded' colorScheme={'green'} >
                    <TabList flexWrap={'wrap'} >

                        <Tab>
                            Lectures
                        </Tab>

                        <Tab>
                            PDFs
                        </Tab>

                    </TabList>

                    <TabPanels>

                        <TabPanel>
                            <SubTopicLoad batch={batch_id} subject={subject_id} tag={tag} type="videos" />
                        </TabPanel>


                        <TabPanel>
                            <SubTopicLoad batch={batch_id} subject={subject_id} tag={tag} type="notes" />
                        </TabPanel>


                    </TabPanels>

                </Tabs>

            </Flex>
        </>

    )
}
