import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import CourceCard from "../../components/CourceCard";
import { Heading, Text, Stack, Flex } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
var loadAsh = require('lodash');

export default function PWJSONHome() {
  // var { loading, error, data } = useFetch("action=getBatches");
  var { loading, error, data } = useFetch("batches.json");

  var showCources = (data) => {

    var pwCrcs = [];

    var cards = [];
    for (const [key, value] of Object.entries(data)) {
      value.map((c, i) => {
if(c.name.includes("10th") && c.name.includes("loaded")){
        pwCrcs.push(c);
}
      })
      }
      
      var finalCrs = loadAsh.groupBy(pwCrcs,"id");

      window.localStorage.setItem("pwCrcs",JSON.stringify(finalCrs));

      for (const [i, c] of Object.entries(finalCrs)) {
        cards.push(<CourceCard key={i + "_" + (i + 1)} name={c[0].name} subhead={`${c[0].class} - ${c[0].board} , ${c[0].exam['0']}`} link={"/pwjsoncource/" + encodeURIComponent(c[0].id)} image={`${c[0].previewImage.baseUrl}${c[0].previewImage.key}`} />);
      }

    return cards;


  }

  return (
    <>
      <Flex width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>






        <Heading
          fontWeight={'bold'}
          fontSize={'3xl'}
          lineHeight={'110%'}>

          <Player
          
            autoplay={true}
            loop={true}
            controls={false}
            src="https://assets10.lottiefiles.com/packages/lf20_iqxl5bjr.json"
            style={{ height: '100px', width: '100px' }}
          ></Player>
          Cources{' '}
          <Text as={'span'} color={'orange.400'}>
            made easy.
          </Text>
        </Heading>


        {loading && <SkeletonPage />}

        <Stack spacing={8} direction='row' wrap={'wrap'} alignItems={'center'} justifyContent={'center'}>

          {data && !error && showCources(data)}
        </Stack>


        {error && <center style={{ padding: 10 }}><h5>Oops, an error occurred.</h5></center>}
      </Flex>

    </>
  )
}
