import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAPv9IY4VN847CoBbmKCo__lE0aZnGJi-8",
    authDomain: "pwfreejee.firebaseapp.com",
    projectId: "pwfreejee",
    storageBucket: "pwfreejee.appspot.com",
    messagingSenderId: "945973570998",
    appId: "1:945973570998:web:5aaab0493a281b9f2ae517"
  };
  
  
  // Your web app's Firebase configuration
  const firebaseConfigVideos = {
    apiKey: "AIzaSyCM14R59rH3IoF3-P8kalnhldvo77a6WTc",
    authDomain: "pwfree-f0864.firebaseapp.com",
    projectId: "pwfree-f0864",
    storageBucket: "pwfree-f0864.appspot.com",
    messagingSenderId: "1029810063215",
    appId: "1:1029810063215:web:9c1c35261d81022b610a9a"
  };


  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

var appvid = initializeApp(firebaseConfigVideos, "other");

// const appvid = initializeApp(firebaseConfigVideos);

export const db = getFirestore(app);
export const dbVid = getFirestore(appvid);



