import SheetsHome from "../sheets/SheetsHome";
import PWHome from "../pw/PWHome";
import { Box, Tabs, Tab, TabPanels, TabPanel, TabList} from "@chakra-ui/react";
import FireHome from "../fire/FireHome";
import PWJSONHome from "../pwjson/PWJSONHome";
import ScorePlusHome from "../scoreplus/ScorePlusHome";


export default function HomePage() {

  return (
    <>
        <Tabs variant='soft-rounded' colorScheme='green'>
          
          <center>
          <TabList alignItems={'center'} justifyContent={'center'}>
            <Tab>PW Cources</Tab>
           
          </TabList>
          </center>

          <TabPanels>

            <TabPanel>
              <br />
              {/* <PWHome /> */}
              <PWJSONHome />
            </TabPanel>

            

          </TabPanels>
        </Tabs>
    </>
  )
}
