
import { useState, useEffect } from "react";
import { Heading, Spacer, Button } from "@chakra-ui/react";

import { ArrowDownIcon } from "@chakra-ui/icons";
import axios from "axios";


const UnacademyNotesLoader = ({ ytdescription, name = "" }) => {

    const [link, setLink] = useState("");
    const [dllink, setdlLink] = useState("");

    const [isDownloading, setisDownloading] = useState(false);

    const downloadFile = (url, fileName) => {
        setisDownloading(true);
        axios({
            url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            setisDownloading(false);
            const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobbedResponse;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }



    useEffect(() => {

        var m = /PDF Notes: (https?:\/\/[^\s]+)/gm.exec(ytdescription);
        // m = m.split(",");
        m = m[1];
        var url = m;


        fetch('https://phpstreamer.herokuapp.com/unshort.php?url=' + url).then(response => response.text())
            .then(long_url => {
                // console.log('Success:', data);
                setLink(long_url.replace("view", "preview"));
                var dllink = long_url.replace("/view?usp=sharing", "");
                dllink = dllink.replace("file/d/", "uc?export=download&id=");
                setdlLink(dllink);

            })
            .catch(err => console.error('AAAW 👻', err))

            ;


    }, [ytdescription]);


    return (
        <>
            <Heading
                fontWeight={'bold'}
                fontSize={'3xl'}
                lineHeight={'110%'}>
                Notes {' '}
            </Heading>

            <Spacer py={3} />

            {dllink && (<>
                <Button
                    isLoading={isDownloading}
                    px={4}
                    fontSize={'sm'}
                    bg={'blue.500'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.600',
                    }}
                    _focus={{
                        bg: 'blue.600',
                    }}
                    loadingText='Downloading'
                    colorScheme='teal'
                    variant='outline'
                    onClick={() => downloadFile(`https://crosdev.herokuapp.com/${dllink}`, `${name} Notes.pdf`)}
                >
                    <ArrowDownIcon />
                    Download Notes
                </Button></>)}
            <Spacer py={3} />



            <iframe src={`${link}`} style={{ border: 0, width: '100%', height: '500px' }}>Your
            </iframe>
        </>
    );
}

export default UnacademyNotesLoader;