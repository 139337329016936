import useFetch from "../../hooks/useFetchPWJSON";
import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import CourceCard from "../../components/CourceCard";
import { Heading,Text,Stack,Flex } from "@chakra-ui/react";
var loadAsh = require('lodash');

export default function ApniKakshaHomeJSON() {
  var { loading, error, data } = useFetch("apnikaksha/batches.json");


  var showCources = (data) => {

    var pwCrcs = [];

    var cards = [];
    for (const [key, value] of Object.entries(data)) {
      value.map((c, i) => {
        pwCrcs.push(c);
      })
      }
      
      var finalCrs = loadAsh.groupBy(pwCrcs,"id");

      window.localStorage.setItem("akCrcs",JSON.stringify(finalCrs));

      for (const [i, c] of Object.entries(finalCrs)) {
        cards.push(<CourceCard key={i + "_" + (i + 1)} name={c[0].course_name} subhead={`${c[0].course_description.substring(0,50)}.....`} link={"/akjsoncource/" + encodeURIComponent(c[0].id)} image={`${c[0].course_thumbnail}`} />);
      }

    return cards;


  }


  return (
    <>
     <Flex width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>

    <Heading
        fontWeight={'bold'}
        fontSize={'3xl'}
        lineHeight={'110%'}>
        Apni Kaksha{' '}
        <Text as={'span'} color={'orange.400'}>
          Cources.
        </Text>
      </Heading>


      {loading && <SkeletonPage />}

      <Stack spacing={8} direction='row' wrap={'wrap'} alignItems={'center'} justifyContent={'center'}>

      {data && !error && showCources(data)}

          </Stack>


      {error && <center style={{padding:10}}><h5>Oops, an error occurred.</h5></center>}
      </Flex>

    </>
  )
}
