import { useParams,useHistory ,useLocation} from 'react-router-dom';
import { useEffect, useState } from "react";
import { ArrowBackIcon, ArrowDownIcon, CopyIcon } from "@chakra-ui/icons";
import { Stack, Container, Heading, Text, useColorModeValue, Link, Button, Spacer, Box, Divider } from "@chakra-ui/react";
import axios from "axios";

export default function PdfView() {

    // var { url, name } = useParams();

    var loc = useLocation();
    var url = new URLSearchParams(loc.search).get("url");
    var name = new URLSearchParams(loc.search).get("name");


    const history = useHistory();

    const [isDownloading, setisDownloading] = useState(false);

    const downloadFile = (url, fileName) => {
        setisDownloading(true);
        axios({
            url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            setisDownloading(false);
            const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobbedResponse;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>
            <Box px={{ base: 1, lg: 20 }}>

                <Button
                    /* flex={1} */
                    onClick={history.goBack}
                    px={4}
                    fontSize={'sm'}
                    bg={'blue.400'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.500',
                    }}
                    _focus={{
                        bg: 'blue.500',
                    }}>
                    <ArrowBackIcon />
                    Go Back
                </Button>


                <br />
                <br />
                <Button
                    /* flex={1} */
                    onClick={()=>{navigator.clipboard.writeText(url); }}
                    px={4}
                    fontSize={'sm'}
                    bg={'blue.400'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.500',
                    }}
                    _focus={{
                        bg: 'blue.500',
                    }}>
                    <CopyIcon />
                    Copy Link
                </Button>


                <Spacer py={'1%'} />

                <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        {name}
                    </Heading>
                    <Spacer py={3} />
                    <Button
                        isLoading={isDownloading}
                        px={4}
                        fontSize={'sm'}
                        bg={'blue.500'}
                        color={'white'}
                        boxShadow={
                            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                        }
                        _hover={{
                            bg: 'blue.600',
                        }}
                        _focus={{
                            bg: 'blue.600',
                        }}
                        loadingText='Downloading'
                        colorScheme='teal'
                        variant='outline'
                        onClick={() => downloadFile(`${url}`, `${name}.pdf`)}
                    >
                        <ArrowDownIcon />
                        Download PDF
                    </Button>
                    <Spacer py={3} />


                    <iframe src={`https://physicswallah.pages.dev/pdf/viewer.html?file=${encodeURIComponent(url)}`} style={{ border: 0, width: '100%', height: '500px' }}>Your
                    </iframe>

                    <br />
                    <br />
                    <br />

            </Box>
        </>
    )
}
