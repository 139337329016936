
import { Text, Flex, Divider, Box, useColorModeValue as mode, Heading } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from 'react-router-dom';
import SingleDataCard from "../singledatacard";

function ViewDataSingle({ s, shownotes }) {
    var bg = mode('#151f21', 'gray.900');

    return shownotes ?
        <Box as="section" py="2">
            <Box maxW="3xl" mx="auto" px={{ md: '8' }}
                borderWidth='1px'
                bg={mode('white', 'gray.700')}
                rounded={{ md: 'lg' }}
                shadow="base"
                overflow="hidden"
                borderRadius='20px'
            >
                <Box px="6" py="4">

                    <Text as="h3" fontWeight="bold" fontSize="md">
                        {s["homeworkIds"][0]["topic"]}
                    </Text>
                </Box>
                <Divider />
            </Box>
        </Box>


        : <> <SingleDataCard key={s._id} thumb={s.videoDetails?.image} title={s.topic + " (" + s.videoDetails?.duration + ")"} />  <br /> </>;
}


export default function SubTopicLoad({ datal, type }) {

    //  types:
    //  videos
    //  notes
    // DppNotes
    // DppVideos

    var shownotes = type == "notes" || type == "DppNotes" ? true : false;



    var bg = mode('#151f21', 'gray.900');
    return (

        <>


            <Flex direction='column' wrap={'wrap'} alignItems={'center'}>

                {datal.map((s, i) => (
                    <>
                        <Link to={
                            shownotes ? `/pdf?url=${encodeURIComponent(s["homeworkIds"][0]["attachmentIds"][0]["baseUrl"] + s["homeworkIds"][0]["attachmentIds"][0]["key"])}&name=${encodeURIComponent(s["homeworkIds"][0]["topic"])}`
                                : `/play?url=${encodeURIComponent(s.url.replace(".mpd", ".m3u8"))}&name=${encodeURIComponent(s["topic"])}`
                        }
                            key={i + 1}
                        >

                            <ViewDataSingle shownotes={shownotes} s={s} />


                        </Link>
                    </>
                ))

                }


                {datal == null || datal.length == 0 && (
                    <>

                        <Heading
                            fontWeight={'bold'}
                            fontSize={'3xl'}
                            lineHeight={'110%'}>
                            Not {' '}
                            <Text as={'span'} color={'red.400'}>
                                Found.
                            </Text>
                        </Heading>
                        <br/>

                        <Heading
                            fontWeight={'bold'}
                            fontSize={'xl'}
                            lineHeight={'110%'}>
                            <Text as={'span'} color={'green.500'}>
                                Data Will Be Updated Soon.
                            </Text>
                        </Heading>
                            <br/>
                            
                        <Player
                            autoplay={true}
                            loop={true}
                            controls={false}
                            src="https://assets6.lottiefiles.com/packages/lf20_i2rkmcsu.json"
                            style={{ height: '300px', width: '300px' }}
                        ></Player>
                    </>
                )}
            </Flex>


        </>

    )
}
