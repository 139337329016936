import { useParams,useHistory,useLocation } from 'react-router-dom';
import JWPlayer from '../JWPLAYER.js';
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";

import { isIOS, isMacOs } from 'react-device-detect';
import { ArrowBackIcon, ArrowDownIcon, CopyIcon } from "@chakra-ui/icons";
import { Stack, Container, Heading, Text, useColorModeValue, Link, Button, Spacer, Box, Divider } from "@chakra-ui/react";


export default function Player() {
    const history = useHistory();
    const toast = useToast()

    const stringToBoolean = (string) => {

        if (!string) return false;
        switch (string.toLowerCase().trim()) {
            case "true":
            case "yes":
            case "1":
                return true;

            case "false":
            case "no":
            case "0":
            case null:
                return false;

            default:
                return Boolean(string);
        }
    }


    // var { url, name } = useParams();

    var loc = useLocation();
    var url = new URLSearchParams(loc.search).get("url");
    var name = new URLSearchParams(loc.search).get("name");


    useEffect(() => {

        toast({
            title: "Now Showing " + name,
            description: "We've played content for you.",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })

        // codes using router.query
        // console.log(router.query);


    }, []);


    var ext = url.split('.').pop();

    ext = ext == 'mp4' ? 'mkv' : ext;

    if (stringToBoolean(localStorage.getItem('useOldPlayer')) || isIOS || isMacOs) {
        ext = ext == 'm3u8' ? 'superm3u8' : ext;
    }

    if (stringToBoolean(localStorage.getItem('useDRMPlayer')) && (url.includes("cdn") || url.includes('cloudfront'))){
        ext = ext == 'm3u8' ? 'embed' : ext;
        url = "https://study.physicswallah.workers.dev/playDrm?url="+url;
        url = url.replace(".m3u8",".mpd")
        window.location.replace(url);
    }

    if (url.includes("yout")) {
        ext = "embed";
        url = url.replace("watch?v=","embed/");
        url = url.replace("&feature=youtu.be","");

        
    }



    return (
        <>
            <Box px={{ base: 1, lg: 20 }}>

                <Button
                    /* flex={1} */
                    onClick={history.goBack}
                    px={4}
                    fontSize={'sm'}
                    bg={'blue.400'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.500',
                    }}
                    _focus={{
                        bg: 'blue.500',
                    }}>
                    <ArrowBackIcon />
                    Go Back
                </Button>

                <br />
                <br />
                <Button
                    /* flex={1} */
                    onClick={()=>{navigator.clipboard.writeText(`https://study.physicswallah.workers.dev/pwmultim3u8?url=${encodeURIComponent(url)}`); }}
                    px={4}
                    fontSize={'sm'}
                    bg={'blue.400'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.500',
                    }}
                    _focus={{
                        bg: 'blue.500',
                    }}>
                    <CopyIcon />
                    Copy Link
                </Button>


                <Spacer py={'1%'} />

                <Heading
                        fontWeight={'bold'}
                        fontSize={'3xl'}
                        lineHeight={'110%'}>
                        {name}
                    </Heading>

                    <Spacer py={15} />


                    <Box px={{ lg: 20 }} >
                        <JWPlayer title={`${name}`} url={`${url}`} extension={ext} poster={"https://physicswallah.pages.dev/uploads/physicswallah.png"} height={{ base: "100%", lg: "80%", }} />
                    </Box>


            </Box>

            <br />
            <br />
            <br />
        </>
    )
}
