import { Switch, Flex, Stack, Container, Heading, Text, useColorModeValue, Button, Spacer } from "@chakra-ui/react";

import { useHistory } from 'react-router-dom';
import { ArrowBackIcon, ArrowDownIcon } from "@chakra-ui/icons";

import { useEffect,useState } from "react";

const Settings = () => {
    var bgx = useColorModeValue('white', 'gray.700');
    const history = useHistory();

    const [isChecked,setisChecked] = useState(false);
    const [isCheckedb,setisCheckedb] = useState(false);

   const stringToBoolean = (string) => {
    if (!string) return false;

        switch(string.toLowerCase().trim()){
            case "true": 
            case "yes": 
            case "1": 
              return true;
    
            case "false": 
            case "no": 
            case "0": 
            case null: 
              return false;
    
            default: 
              return Boolean(string);
        }
    }


    useEffect(() => {
        const checked = typeof window !== 'undefined' ?  stringToBoolean(localStorage.getItem('useOldPlayer')) : false;
        console.log(checked,stringToBoolean(localStorage.getItem('useOldPlayer')),"HI")
        setisChecked(checked);

        const checkedb = typeof window !== 'undefined' ?  stringToBoolean(localStorage.getItem('useDRMPlayer')) : false;
        console.log(checkedb,stringToBoolean(localStorage.getItem('useDRMPlayer')),"HI")
        setisCheckedb(checkedb);
      }, [typeof window])

      const setLegacyPlayer = (e) => {
        setisChecked(e.target.checked);
        localStorage.setItem("useOldPlayer", e.target.checked);
    }

    const setLegacyPlayerb = (e) => {
        setisCheckedb(e.target.checked);
        localStorage.setItem("useDRMPlayer", e.target.checked);
    }

    return (<>


        <Button
            marginLeft={10}
            marginTop={10}
            /* flex={1} */
            onClick={history.goBack}
            px={4}
            fontSize={'sm'}
            bg={'blue.400'}
            color={'white'}
            boxShadow={
                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            }
            _hover={{
                bg: 'blue.500',
            }}
            _focus={{
                bg: 'blue.500',
            }}>
            <ArrowBackIcon />
            Go Back
        </Button>

        <Spacer py={'0.5%'} />

        <Container maxW={'100%'} bg={bgx}>
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 4, md: 6 }}
                py={{ base: 10, md: 10 }}>
                <Heading
                    fontWeight={'bold'}
                    fontSize={'3xl'}
                    lineHeight={'110%'}>
                    Main{' '}
                    <Text as={'span'} color={'orange.400'}>
                        Settings
                    </Text>
                </Heading>

            </Stack>


            <Flex direction={'column'} alignItems={'center'} >



                <Stack spacing={6} wrap={'wrap'} alignItems={'center'}
                    direction={'row'}
                >



                    <Text fontSize='xl'>Use Legacy Player</Text>



                    {typeof window !== 'undefined' &&  (
                        <Switch colorScheme='blue' size='lg'
                        isChecked={isChecked}
                            onChange={setLegacyPlayer}
                        />
                    )}


                </Stack>


<br />
                
                <Stack spacing={6} wrap={'wrap'} alignItems={'center'}
                    direction={'row'}
                >




                    <Text fontSize='xl'>Use DRM Player</Text>



                    {typeof window !== 'undefined' &&  (
                        <Switch colorScheme='blue' size='lg'
                        isChecked={isCheckedb}
                            onChange={setLegacyPlayerb}
                        />
                    )}


                </Stack>


            </Flex>
            <Spacer py={'0.5%'} />

        </Container>

    </>);
}



export default Settings;