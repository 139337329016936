import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
    Spacer,
  } from '@chakra-ui/react';
  
  
  
  export default function SingleDataCard({ title, thumb }) {
    return (
      <>
        {/* <Box
            role={'group'}
            p={6}
            flexDirection={'row'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            zIndex={1}>
            <Box
              rounded={'lg'}
              mt={-12}
              pos={'relative'}
              height={'230px'}
              _after={{
                transition: 'all .3s ease',
                content: '""',
                w: 'full',
                h: 'full',
                pos: 'absolute',
                top: 5,
                left: 0,
                filter: 'blur(15px)',
                zIndex: -1,
              }}
              _groupHover={{
                _after: {
                  filter: 'blur(20px)',
                },
              }}>
              <Image
                rounded={'lg'}
                height={230}
                width={282}
                objectFit={'fill'}
                src={thumb}
                alt={title}
              />
            </Box>
            <Stack pt={10} align={'center'}>
              <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
                Lecture
              </Text>
              <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
                {title}
              </Heading>
            </Stack>
          </Box>
                    <Spacer height={10}></Spacer> */}
  
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'lg'}
         
                  >
  
        <Stack
        flexDirection={'row'}
        alignItems={'center'}
        
        >
          <Image
            rounded={'lg'}
            height={'8rem'}
            width={'10rem'}
            objectFit={'fill'}
            src={thumb}
            alt={title}
          />
  
          <Stack py={3} align={'center'}>
            <Heading fontSize={'sm'} fontFamily={'body'} fontWeight={500} p={5}>
              {title}
            </Heading>
          </Stack>
  
        </Stack>
      </Box>
      </>
    );
  }