import {  Text,  Flex, Divider, Box, useColorModeValue as mode} from "@chakra-ui/react";
import { Link } from 'react-router-dom';

export default function SubjectLoad({ subjectData }) {

    const serialize = (obj) => {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }

    return (

        <>



            <Flex direction='column-reverse' wrap={'wrap'} alignItems={'center'}>

                {subjectData.map((s, i) => (
                        <>
                        <Link key={i+1} to={`/sheetsSingleData?${serialize({lecture:s.class_link,name:s.title,dpp:s.dpp,notes:s.notes})}`}>
                                {/* <Button
                            w={'full'}
                            key={i+1}
                                mt={8}
                                bg={bg}
                                color={'white'}
                                rounded={'md'}
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg',
                                }}>
                                {s.name}

                            </Button> */}

                                <Box as="section" py="2" mx="3">
                                    <Box maxW="3xl" mx="auto" px={{ md: '8' }}
                                        borderWidth='1px' 
                                        bg={mode('white', 'gray.700')}
                                        rounded={{ md: 'lg' }}
                                        shadow="base"
                                        overflow="hidden"
                                        borderRadius='20px'
                                        >
                                            <Box px="6" py="4">
                                                <Text as="h3" fontWeight="bold" fontSize="md">
                                                 {s.title}
                                                </Text>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>
                            </Link>
                        </>
                    ))

                }
            </Flex>


        </>

    )
}
