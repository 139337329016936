import { Heading, Text,  Flex,  Tabs, Tab, TabPanels, TabPanel, TabList} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
// import useFetch from "../../hooks/useFetchPWJSON";
// import SkeletonPage from "../../components/SkeletonPage/SkeletonPage";
import SubjectLoad from "../../components/pwjson/SubjectLoad";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export default function CourceDetailsJSON() {


  var { cid } = useParams();
  // var { loading, error, data } = useFetch(`topics/${id}.json`);

  const [cdata, setcData] = useLocalStorage("pwCrcs","");


  return (
    <>
      <Flex as="home" width="100%" align="center" px={10} wrap={'wrap'} direction={'column'} paddingBottom={2}>

        <Heading
          fontWeight={'bold'}
          fontSize={'3xl'}
          lineHeight={'110%'}>
          Cource{' '}
          <Text as={'span'} color={'orange.400'}>
            Subjects.
          </Text>
        </Heading>


        {/* 
        <Flex direction='row' wrap={'wrap'} alignItems={'center'}>

          {data && !error &&
            data?.subjects?.map((s, i) => (
              <Button
                mt={8}
                marginLeft={8}
                rounded={'md'}
                _activeLink={
                  {
                    color: 'white',
                    backgroundColor: 'blue'
                  }
                }
              >
                {s.subject}
              </Button>
            ))

          }
        </Flex> */}


        <br />

        <Tabs variant='soft-rounded' colorScheme={'green'} >
          <TabList flexWrap={'wrap'} >
            {cdata && cdata[cid][0].details.subjects.map((s, i) => (
                <Tab key={i + 1}
                >
                  {s.subject}
                </Tab>
              ))
            }
          </TabList>

          <TabPanels>
          {cdata && cdata[cid][0].details.subjects.map((s, i) => (
                <TabPanel key={i + 1}
                >
                  <SubjectLoad subject={s._id} />
                  
                  </TabPanel>
              ))
            }
          </TabPanels>

        </Tabs>



      </Flex>
    </>

  )
}
