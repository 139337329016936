import { useColorModeValue } from '@chakra-ui/react';



const SvgLogo = ({ full = 0 }) => {

    var bgx = useColorModeValue('#000', '#fff');


    return (
        full ? <>

            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="300" height="115.000000pt" viewBox="0 0 448.000000 115.000000" preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,115.000000) scale(0.100000,-0.100000)" fill={`${bgx}`} stroke="none">
                    <path d="M420 1044 c-157 -31 -318 -142 -379 -262 -26 -50 -26 -52 -2 -52 12 0 29 16 45 43 88 146 254 237 431 237 177 0 344 -89 435 -230 20 -31 39 -50 51 -50 27 0 24 9 -19 76 -97 149 -251 233 -437 239 -55 2 -111 1 -125 -1z" />
                    <path d="M421 959 c-24 -5 -74 -23 -110 -41 l-66 -32 110 -6 c61 -3 119 -10 130 -17 38 -21 57 -51 57 -89 0 -69 -56 -112 -150 -113 l-43 -1 3 -67 3 -68 75 -6 c41 -3 67 -6 57 -7 -10 -1 -20 -8 -23 -16 -5 -13 70 -233 85 -249 4 -4 21 33 39 82 30 87 30 91 15 130 -10 26 -25 44 -42 50 -20 7 -7 9 59 10 60 0 79 -3 63 -9 -13 -5 -23 -16 -23 -24 0 -13 77 -236 84 -243 6 -6 76 214 76 236 0 13 -9 25 -22 31 -18 7 -10 9 35 9 l58 1 -25 -22 c-19 -17 -41 -67 -79 -180 -29 -87 -51 -158 -47 -158 20 0 116 79 149 123 133 176 114 406 -46 558 -109 103 -269 148 -422 118z" />
                    <path d="M177 821 c-102 -109 -138 -246 -101 -386 51 -197 225 -325 442 -325 91 0 192 22 192 42 0 6 -19 65 -42 130 l-41 120 -43 -117 c-23 -65 -46 -121 -52 -127 -6 -6 -23 26 -47 89 -21 54 -52 134 -69 178 l-31 80 -75 6 c-41 3 -67 6 -57 7 29 3 37 45 37 187 0 108 -3 137 -16 149 -26 26 -49 18 -97 -33z" />
                    <path d="M350 770 c0 -99 0 -100 24 -100 66 0 122 57 111 115 -7 43 -57 85 -100 85 l-35 0 0 -100z" />
                    <path d="M1493 759 c15 -9 17 -27 17 -151 0 -118 -3 -145 -17 -160 -15 -17 -13 -18 48 -17 35 1 56 3 47 6 -15 4 -18 17 -18 84 0 66 3 82 20 99 16 16 25 18 40 10 17 -9 20 -21 20 -93 0 -51 -5 -88 -12 -95 -9 -9 2 -12 53 -11 35 1 57 3 47 6 -13 3 -17 21 -20 93 -2 69 -7 92 -22 109 -24 27 -66 27 -100 0 l-26 -20 0 75 0 76 -47 0 c-38 -1 -44 -3 -30 -11z" />
                    <path d="M3588 760 c22 -9 22 -12 20 -157 -3 -146 -3 -148 -28 -160 -22 -11 -16 -13 55 -11 44 0 70 3 58 5 -23 4 -23 6 -23 169 l0 164 -52 -1 c-43 0 -48 -2 -30 -9z" />
                    <path d="M3748 763 c22 -4 22 -7 22 -153 0 -142 -1 -150 -22 -164 -21 -15 -18 -16 52 -15 41 1 65 4 53 6 -23 4 -23 6 -23 169 l0 164 -52 -1 c-29 -1 -43 -4 -30 -6z" />
                    <path d="M4163 763 c15 -4 17 -20 17 -155 0 -132 -2 -152 -17 -164 -16 -12 -9 -13 47 -13 36 1 57 3 47 6 -17 4 -19 15 -17 91 2 48 2 122 1 165 l-1 77 -47 -1 c-27 -1 -40 -3 -30 -6z" />
                    <path d="M2282 751 c-8 -4 -12 -19 -10 -32 2 -19 9 -24 33 -24 33 0 45 25 25 50 -14 17 -29 19 -48 6z" />
                    <path d="M1203 733 l28 -4 -3 -142 c-3 -141 -3 -141 -28 -148 -14 -4 15 -7 65 -7 50 1 78 3 63 5 -27 4 -28 6 -28 67 l0 64 57 4 c46 4 61 10 80 32 31 37 30 80 -3 111 -26 24 -33 25 -143 24 -64 -1 -104 -4 -88 -6z m161 -19 c25 -25 21 -99 -6 -118 -12 -9 -30 -16 -40 -16 -16 0 -18 10 -18 75 0 73 1 75 24 75 14 0 32 -7 40 -16z" />
                    <path d="M2841 733 c10 -2 36 -55 69 -141 54 -140 73 -174 89 -157 4 6 21 44 36 85 15 41 31 78 34 82 4 5 22 -31 41 -80 21 -52 41 -87 49 -87 10 0 31 50 63 147 34 102 54 148 66 151 9 3 -3 5 -28 5 -28 0 -37 -2 -24 -6 21 -7 20 -9 -16 -117 -22 -63 -40 -104 -44 -95 -3 8 -21 59 -40 112 l-35 97 22 4 c12 2 -4 5 -35 6 -32 0 -58 -2 -58 -6 0 -4 7 -26 16 -50 16 -41 15 -45 -7 -108 -12 -36 -26 -62 -30 -58 -6 7 -79 198 -79 208 0 2 10 6 23 8 12 2 -12 4 -53 4 -41 1 -68 -1 -59 -4z" />
                    <path d="M1760 650 c0 -5 6 -10 13 -10 12 0 107 -198 107 -224 0 -19 -39 -66 -55 -66 -8 0 -17 9 -20 20 -9 27 -45 27 -45 0 0 -29 42 -47 75 -32 33 15 54 51 100 174 21 57 47 113 58 126 l20 22 -41 0 c-42 0 -59 -16 -23 -22 18 -3 17 -7 -9 -73 -26 -66 -29 -68 -38 -45 -6 14 -19 46 -31 73 -17 38 -18 47 -6 47 8 0 15 5 15 10 0 6 -27 10 -60 10 -33 0 -60 -4 -60 -10z" />
                    <path d="M2055 636 c-41 -42 -31 -67 42 -106 41 -22 48 -30 48 -55 0 -26 -4 -30 -28 -33 -30 -4 -67 21 -67 45 0 7 -4 13 -10 13 -5 0 -10 -15 -10 -32 l0 -33 66 -3 c80 -4 114 13 114 58 0 34 -17 53 -72 81 -44 23 -56 42 -38 64 20 24 56 18 79 -12 l20 -28 1 30 c0 30 -1 30 -60 33 -53 3 -63 0 -85 -22z" />
                    <path d="M2434 626 c-28 -28 -34 -42 -34 -75 0 -80 41 -121 119 -121 39 0 91 31 91 53 -1 7 -6 5 -13 -4 -16 -21 -51 -39 -76 -39 -33 0 -51 38 -51 108 0 75 12 102 45 102 19 0 26 -7 33 -32 6 -26 14 -33 33 -33 17 0 25 6 27 22 5 31 -33 53 -91 53 -42 0 -54 -5 -83 -34z" />
                    <path d="M2672 648 c-26 -13 -39 -54 -25 -77 5 -9 27 -26 49 -38 53 -29 64 -40 64 -63 0 -42 -85 -37 -96 6 -9 35 -24 30 -24 -9 l0 -33 68 -2 c57 -3 71 0 90 18 41 38 25 76 -48 116 -38 21 -50 33 -50 50 0 17 7 25 24 30 29 7 66 -11 66 -31 0 -8 5 -15 10 -15 6 0 10 12 10 28 0 26 -2 27 -57 29 -32 1 -68 -3 -81 -9z" />
                    <path d="M3339 639 c-16 -16 -18 -23 -8 -35 19 -23 39 -16 51 17 8 23 16 29 32 27 17 -2 22 -11 24 -44 l3 -42 -46 -7 c-75 -11 -104 -54 -69 -103 19 -28 76 -30 103 -3 18 18 19 18 35 0 28 -34 98 -14 83 23 -4 8 -6 7 -6 -4 -1 -10 -8 -18 -16 -18 -12 0 -15 16 -15 80 0 73 -3 83 -25 105 -32 33 -114 36 -146 4z m101 -128 c0 -41 -20 -67 -45 -57 -9 3 -15 18 -15 41 0 28 5 38 23 45 35 14 37 12 37 -29z" />
                    <path d="M3919 639 c-16 -16 -18 -23 -8 -35 19 -23 40 -16 52 16 20 52 67 29 67 -33 0 -22 -5 -25 -50 -31 -79 -11 -110 -54 -74 -104 20 -28 76 -30 103 -3 19 19 20 19 37 0 30 -33 96 -14 81 23 -4 8 -6 7 -6 -4 -1 -10 -8 -18 -16 -18 -12 0 -15 16 -15 80 0 73 -3 83 -25 105 -32 33 -114 36 -146 4z m109 -121 c4 -32 -29 -74 -51 -65 -12 4 -17 18 -17 42 0 28 5 38 23 45 31 12 42 7 45 -22z" />
                    <path d="M4269 639 c-22 -19 -23 -19 -2 -10 28 14 49 5 57 -22 9 -36 7 -137 -4 -158 -10 -18 -7 -19 47 -18 32 1 50 3 41 6 -15 4 -18 17 -18 92 0 74 -3 91 -20 109 -26 28 -70 28 -101 1z" />
                    <path d="M2253 643 c15 -4 17 -17 17 -98 0 -79 -3 -96 -17 -104 -14 -8 0 -10 52 -10 39 1 62 3 53 6 -16 4 -18 18 -18 109 l0 104 -52 -1 c-29 -1 -45 -3 -35 -6z" />
                    <path d="M30 313 c171 -322 615 -388 879 -131 46 45 103 127 115 166 6 18 4 22 -12 22 -14 0 -30 -18 -52 -57 -140 -247 -486 -325 -728 -162 -70 46 -128 108 -163 174 -18 33 -30 45 -47 45 l-23 0 31 -57z" />
                </g>
            </svg>

        </> :

            <>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="300" height="100" viewBox="0 0 448.000000 115.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,115.000000) scale(0.100000,-0.100000)" fill={`${bgx}`} stroke="none">
                        <path d="M420 1044 c-157 -31 -318 -142 -379 -262 -26 -50 -26 -52 -2 -52 12 0 29 16 45 43 88 146 254 237 431 237 177 0 344 -89 435 -230 20 -31 39 -50 51 -50 27 0 24 9 -19 76 -97 149 -251 233 -437 239 -55 2 -111 1 -125 -1z" />
                        <path d="M421 959 c-24 -5 -74 -23 -110 -41 l-66 -32 110 -6 c61 -3 119 -10 130 -17 38 -21 57 -51 57 -89 0 -69 -56 -112 -150 -113 l-43 -1 3 -67 3 -68 75 -6 c41 -3 67 -6 57 -7 -10 -1 -20 -8 -23 -16 -5 -13 70 -233 85 -249 4 -4 21 33 39 82 30 87 30 91 15 130 -10 26 -25 44 -42 50 -20 7 -7 9 59 10 60 0 79 -3 63 -9 -13 -5 -23 -16 -23 -24 0 -13 77 -236 84 -243 6 -6 76 214 76 236 0 13 -9 25 -22 31 -18 7 -10 9 35 9 l58 1 -25 -22 c-19 -17 -41 -67 -79 -180 -29 -87 -51 -158 -47 -158 20 0 116 79 149 123 133 176 114 406 -46 558 -109 103 -269 148 -422 118z" />
                        <path d="M177 821 c-102 -109 -138 -246 -101 -386 51 -197 225 -325 442 -325 91 0 192 22 192 42 0 6 -19 65 -42 130 l-41 120 -43 -117 c-23 -65 -46 -121 -52 -127 -6 -6 -23 26 -47 89 -21 54 -52 134 -69 178 l-31 80 -75 6 c-41 3 -67 6 -57 7 29 3 37 45 37 187 0 108 -3 137 -16 149 -26 26 -49 18 -97 -33z" />
                        <path d="M350 770 c0 -99 0 -100 24 -100 66 0 122 57 111 115 -7 43 -57 85 -100 85 l-35 0 0 -100z" />
                        <path d="M30 313 c171 -322 615 -388 879 -131 46 45 103 127 115 166 6 18 4 22 -12 22 -14 0 -30 -18 -52 -57 -140 -247 -486 -325 -728 -162 -70 46 -128 108 -163 174 -18 33 -30 45 -47 45 l-23 0 31 -57z" />
                    </g>
                </svg>
            </>
    );
}

export default SvgLogo;